<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='name' label='企业名称'>
        <el-input v-model="form.data.name" placeholder="请输入"  clearable></el-input>
      </el-form-item>
      <el-form-item prop='principal' label='负责人'>
        <el-input v-model="form.data.principal" placeholder="请输入" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='mobile' label='联系电话'>
        <el-input v-model="form.data.mobile" placeholder="请输入" maxlength="11" clearable></el-input>
      </el-form-item>
      <el-form-item prop='address' label='注册地址'>
        <el-input v-model="form.data.address" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item prop='logo' label='企业logo'>
        <el-upload
          class="avatar-uploader-login"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.logo" :src="form.data.logo" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {REG_EXP} from '@/util'
  import common from '@/util'
  export default {
    name: 'CompanyEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            principal: '',
            mobile: '',
            address: '',
            logo: ''
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change' }],
            principal: [{ required: true, message:'必填项', trigger: 'change' }],
            mobile: [
              { required: true, message:'必填项', trigger: 'change' },
              { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
            ],
            address: [{ required: true, message: '必填项', trigger: 'change' }],
            logo: [{ required: true, message: '请上传logo', trigger: 'change' }],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
        this.getClassify();
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/admin/flex_firm/add' : '/admin/flex_firm/edit'
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              principal: this.form.data.principal,
              mobile: this.form.data.mobile,
              address: this.form.data.address,
              logo: this.form.data.logo,
            }
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      // 上传主图
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.logo = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      },
    }
  }
</script>

<style scoped>
.avatar-uploader-login .el-icon-plus {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-login .avatar-uploader-icon{
  line-height: 100px !important;
}
.avatar-uploader-login .avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-image .el-icon-plus {
  width: 234px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-image .avatar {
  width: 234px;
  height: 100px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 100px !important;
}
.el-upload__tip {
  color: #f56c6c;
  margin-top: 0;
}
</style>