<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="负责人:" prop="principal">
          <el-input clearable v-model="table.params.principal" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="电话号码:" prop="mobile">
          <el-input clearable v-model="table.params.mobile" maxlength="11" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:logo="{row}">
        <el-image
          v-if="!!row.logo"
          style="width: 50px; height: 50px; line-height: 30px;"
          :src="row.logo"
          fit="cover"
          :preview-src-list="[row.logo]">
        </el-image>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" icon="el-icon-user" @click="showClerk(row)">驻店员</el-button>
        <el-button type="text" icon="el-icon-delete" @click="remove(row)">删除</el-button>
      </template>
    </VTable>

    <el-dialog
      title="驻店员"
      :visible.sync="dialogVisible"
      width="660px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form
        ref='elFormDom'
        label-width='80px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'>
        <el-form-item label="驻店员" prop="clerk_ids">
          <el-select v-model="form.data.clerk_ids" placeholder="请选择" filterable  multiple>
            <el-option v-for="item in clerkList" :label="item.clerk_name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <edit ref="edit" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'CompanyIndex',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "企业名称", hidden: false },
        { name: "logo", label: "企logo", width:'80',hidden: false },
        { name: "principal", label: "负责人", hidden: false },
        { name: "mobile", label: "联系电话", hidden: false },
        { name: "address", label: "注册地址", showTooltip: true, hidden: false },
        { name: "address_count", label: "用工地址", hidden: false },
        { name: "work_count", label: "累计用工数", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "220", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          principal: '',
          mobile:'',
          name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      dialogVisible: false,
      form: {
        data: {
          id: '',
          clerk_ids: [],
          clerk: [],
        },
        rules: {
          clerk_ids: [{ required: true, message:'必填项', trigger: 'change' }]
        }
      },
      clerkList: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/flex_firm/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取驻店员
    getClerk() {
      this.$http.get('/admin/flex_clerk/list', { params:{page:1,count:10000} }).then(res => {
        if(res.code == 1) {
          // this.clerkList = res.data.list;
          this.clerkList = res.data.list.map(item => {
            return {
              clerk_name: `${item.nickname} - ${item.account}`,
              ...item
            }
          })
          console.log(this.clerkList)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 驻店员
    showClerk(row) {
      this.dialogVisible = true;
      this.form.data.id = row.id;
      this.form.data.clerk = row.clerk;
      this.form.data.clerk_ids = row.clerk.map(v => {return v.id})
      console.log(this.form.data.clerk_ids)
      console.log(6666)
      this.getClerk();
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            id: this.form.data.id,
            clerk_ids: this.form.data.clerk_ids.join(","),
          }
          console.log(_params)
          this.$http.post('/admin/flex_firm/clerk', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功！')
              this.dialogVisible = false;
              this.getTable();
            }
          })
        }
      })
    },
    handleClose() {
      this.$refs.elFormDom.resetFields();
      this.dialogVisible = false;
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/flex_firm/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
  }
}
</script>